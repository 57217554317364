<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('trainings.roles.view')">
          <actions
            slot="actions"
            crud-links="roles"
            controller="DcpiRoles"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list :title="$t('tables.headings.name')">
            {{ role.name }}
          </text-list>
          <text-list
            v-if="role.parent_dcpi_role"
            :condition="currentUser.can('DcpiRoles', 'view')"
            :title="$t('tables.headings.parent_role')"
            :label="role.parent_dcpi_role.name"
            :to="{ name: 'rolesView', params: {id: role.parent_dcpi_role.id}}"
          />
          <text-list :title="$t('tables.headings.can_train')">
            {{ $t(role.trainer ? 'layout.yes' : 'layout.no') }}
          </text-list>
        </va-card>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'roles-view',
  components: {
    Actions,
    TextList,
  },
  data () {
    return {
      role: {},
      loading: false,
      error: false,
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {
    '$route.params.id' (val) {
      this.updateData()
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `dcpi-roles/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const roleId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(roleId))
      } catch (err) {
        // console.log('Error fetching role data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.role = u.data.data
    },
  },
}
</script>
